<style>
  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
    margin-left: 10px;
    margin-top: 5px;
    margin-right: 10px;
  }

  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .3s;
    transition: .23;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    top: 1px;
    left: 1px;
    /* bottom: 4px; */
    background-color: white;
    -webkit-transition: .3s;
    transition: .3s;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
</style>

<template>
  <section class="content">
    <div class="flex justify-content-center">
      <div class="w-100 card">
        <div class="overlay" v-if="loadingContent"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
        <div class="card-body">
          <div class="row">
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Shipper
                  </div>
                  <h4 class="mb-3">
                    <span> {{ allSaldo.nama_shipper }} </span>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Total Saldo
                  </div>
                  <h4 class="mb-3">
                    Rp<span> {{ allSaldo.current }} </span>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Bank Shipper
                  </div>
                  <h4 class="mb-0">
                    <span> {{ !allSaldo.bank ? '-' : allSaldo.bank }} </span>
                  </h4>
                  {{ (!allSaldo.code ? '' : allSaldo.code) }}
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Dana Tertahan
                  </div>
                  <h4 class="mb-0">
                    <span> {{ allSaldo.retainedFundStr }} </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3" style="row-gap: 1rem;">
            <div class="col-auto" :v-if="false">
              <div class="row align-items-center" style="row-gap: 1rem;">
                <div class="col-auto">
                  <div class="text-muted font-13 flex">
                    Filter berdasarkan tanggal jurnal dibuat
                  </div>
                </div>
                <div class="col-auto">
                  <label class="switch m-0">
                    <input type="checkbox" name="approved" @change="onChangeAprovedFilter($event)">
                    <span class="slider round"></span>
                  </label>
                </div>
                <div class="col-12 col-sm input-group" ref="date_picker">
                    <input type="text" ref="daterange" class="form-control" style="border-right: 0"/>
                    <div class="input-group-append">
                    <div
                        class="input-group-text"
                        style="background-color: #fff">
                        <span><span class="fa fa-calendar"></span></span>
                    </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="col-auto ml-auto">
              <div class="btn-group" ref="toolbar">
                <button type="button" data-action="read" class="btn btn-default"><i
                  class="fa fa-sync-alt"></i></button>
                <button type="button" :disabled="downloading" v-on:click="downloadXlsx"
                  data-action="export-xlsx" class="btn btn-default"><i class="fa fa-download"></i> <span class="d-none d-md-inline-block">Download</span></button>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-hover" id="tblsaldo" ref="tablesaldo">
              <thead>
               <tr>
                  <th>TANGGAL</th>
                  <th>TIPE</th>
                  <th>DESKRIPSI</th>
                  <th>JUMLAH</th>
                  <th>KREDIT</th>
                  <th>SALDO</th>
                  <th>STATUS</th>
                  <th>INVOICE ID</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          <div class="p-2"></div>
          <router-link
            :to="{ path: '/saldo/shipper' }"
            class="btn btn-secondary"
          >
            Kembali
          </router-link>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import { authFetch, createTable, formatCurrency, auth } from "@/libs/hxcore";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import moment from "moment";
export default {
  name: "RincianSaldo",
  data() {
    return {
      //   dateStart: "",
      allSaldo: {},
      errors: [],
      method: "",
      approved: false,
      dateStart: '',
			dateEnd: '',
			dt1: moment().startOf("month"),
      dt2: moment(),
      downloading: false,
      filter: {},
      show: false,
      roleid:0,
      loadingContent: false,
    };
  },
  computed: {
    voidRole() {
        const allowRoles = ['1', '2', '4'];
        return allowRoles.includes(this.roleid);
    },
  },
  created: function () {
    // this.roles = this.$route.meta.roles;
    // console.log("load initial data", this.$route);
    var uinfo = auth.user();
    if (uinfo) {
      this.roleid = uinfo.role_id;
    }

    this.loadSaldo(this.$route.params.id);

    if (this.$route.params.filter){
			this.filter = this.$route.params.filter;
    }

    if (this.filter.dt1 && this.filter.dt2) {
			this.dt1 = moment(this.filter.dt1);
			this.dt2 = moment(this.filter.dt2);
		}

    this.dateStart = this.dt1.format('YYYY-MM-DD');
		this.dateEnd = this.dt2.format('YYYY-MM-DD');

    this.filter.dt1 = !this.filter.dt1 ? moment().format("YYYY-MM-DD") : this.filter.dt1;
    this.filter.dt2 = !this.filter.dt2 ? moment().format("YYYY-MM-DD") : this.filter.dt2;
  },
  methods: {
    loadSaldo: function (id) {
      authFetch("/payment/saldo/allsaldo/" + id).then((res) => {
        res.json().then((json) => {
          this.allSaldo = json.data;
          this.allSaldo.current = formatCurrency(this.allSaldo.current);
          this.allSaldo.nama_shipper = json.data.nama_shipper;
          this.allSaldo.retainedFundStr = this.allSaldo.retainedFund==0?'-' : 'Rp' + formatCurrency(
            this.allSaldo.retainedFund
          );
        });
      });
    },
    handleClick(e) {},
    onChangeAprovedFilter(event) {
      const e = this.$refs;
      const self = this;

      this.approved = event.target.checked;
      if (event.target.checked == true){
        $(e.date_picker).show();
      } else {
        $(e.date_picker).hide();
      }

      self.onDate();
		},
    onDate() {
			this.tbl.api().ajax.reload();
		},
    downloadXlsx: function (e) {
      this.downloading = true;
      var table = $('#tblsaldo').DataTable();
      var length = table.page.info().recordsTotal;
      
      if (length > 20000) {
        Swal.fire("Proses gagal", `Maksimal data sebanyak kurang lebih 20.000 data.`, "error");
        this.downloading = false;
        return false;
      }
      
      var filter = JSON.parse(JSON.stringify(this.filter));
          filter.q = $("input[name=q]").val();
          filter.category = $("select[name=category]").val();
          filter.approved = $("input[name=approved]").prop('checked');

      var data = Object.keys(filter)
        .map(
          (key) =>
          encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
        )
        .join("&");
      this.loadingContent = true;
      authFetch("/report/saldo/excell/" + this.$route.params.id, {
        method: "POST",
        body: data,
      })
      .then((response) => {
        if (response.status === 201) {
        } else if (response.status === 400) {
        } else if (response.status === 500){
          Swal.fire("Timeout!", ``, "error");
          this.loadingContent = false;
          this.msg = '';
          return false;
        }

        return response.blob();
      })
      .then((blob) => {
        setTimeout(() => {
          this.downloading = false;
        }, 1000);
        this.loadingContent = false;
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "data.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
    },
  },
  mounted() {
    const e = this.$refs;
    const self = this;
    this.tbl = createTable(e.tablesaldo, {
      title: "",
      roles: ["read"],
      ajax: "/payment/saldo/list/" + this.$route.params.id,
      roles: this.$route.params.roles,
      selectedRowClass: "",
      toolbarEl: e.toolbar,
      serverSide: true,
      frame: false,
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      displayLength: 10,
      lengthChange: true,
      sort:false,
      columns: [
        { data: "show_date" },
        { data: "trans_type" },
        { data: "note" },
        { 
          data: "debit", 
          class:'text-right',
          render: function(data, type, row, meta) {
            if((!data || data==0) && row.credit > 0)data = -row.credit;
            return formatCurrency(data);
          }
        },
        { 
          data: "credit", 
          visible:false,
          class:'text-right',
          render: function(data, type, row, meta) {
            return formatCurrency(data);
          }
        },
        { data: "saldo" ,
          class:'text-right',
          render: function(data, type, row, meta) {
            return formatCurrency(data);
          }
          },
        { data: "status", render: function (data, type, row, meta) {
            let status = '';
            if(row.active < 1)
            {
                return `<span class="badge badge-danger">DITOLAK</span>`;
            } else {
                if (row.status == 0) {
                    status = `<span class="badge badge-light">REQUEST</span>`;
                } else if (row.status == 1) {
                    status = `<span class="badge badge-success">SUKSES</span>`;
                } else if (row.status == 2)  {
                    status = `<span class="badge badge-info">PROSES</span>`;
                } else if (row.status == 3)  {
                    status = `<span class="badge badge-warning text-light">PENDING</span>`;
                }
            }
            return status;
        } },
        { data: "invoice_code" },
      ],
      filterBy: [2, 7],
      paramData: function (value) {
				value["approved"]= $("input[name=approved]").prop('checked');

        var filter = JSON.parse(JSON.stringify(self.filter));
        for (const key in filter) {
					value[key] = filter[key] === false ? 0 : filter[key];
				}
			},
      rowCallback: function (row, data) {
        
        if (data.trans_type == 0) {
          if(data.status == 0)$("td:eq(6)", row).html(
              data.invoice_code
          );
          $("td:eq(1)", row).html(
            `<span class="badge badge-info">TOP UP</span>`
          );
        } else if (data.trans_type == 1) {
          if(data.debit > 0)
          $("td:eq(1)", row).html(
            `<span class="badge badge-info">REKONSILIASI</span>`
          );

          else $("td:eq(1)", row).html(
            `<span class="badge badge-danger">REKONSILIASI</span>`
          );
        } else if (data.trans_type == 11) {
          $("td:eq(1)", row).html(
            `<span class="badge badge-danger">WITHDRAW</span>`
          );
          //$("td:eq(3)", row).html(data.debit ? data.debit : -data.credit);
        } else {
          $("td:eq(1)", row).html("");
        }
      },
    });

    $(e.date_picker).hide();

		new Daterangepicker(
			this.$refs.daterange, {
				startDate: this.dt1,
				endDate: this.dt2,
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.dateStart = d1.format("YYYY-MM-DD");
				self.dateEnd = d2.format("YYYY-MM-DD");
				self.filter.dt1 = self.dateStart;
				self.filter.dt2 = self.dateEnd;
				self.onDate();
			}
		);
  },
};
</script>